import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import hero_img2 from "../../Component/assets/images/three.webp";
import white from "../assets/images/icons/dot-white.png";
import video from '../assets/1.mp4'
export default function HeroSection( {heroSection, refinance}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(heroSection.PhoneNo)}`;
  return (
    <div className="rel">
      <div className="Hero_Bg fix">
     
        <div className="container">
          <div className="row">
            <div className="col-1">
              <div className="col-1-content">
                <div className="animate-from-left" ref={cardRefs[0]}>
                  <span >
                    <img src={white} alt="title_vector" />
                   {heroSection.SubHeading}
                  </span>
                </div>
                <div  className=" col-2 mobile" >
              <div className="col-2-image">
                <img src={refinance.image} alt="hero side image" />
              </div>
            </div>
                <div className="animate-from-right" ref={cardRefs[1]}>
                  <h1>
                   {heroSection.Heading}
                  </h1>
                </div>
                <div className="animate-from-right" ref={cardRefs[2]}>
                <div>
                  <p>
                   {heroSection.Text}
                  </p>
                </div>
                <div className="btn-group desktop">
                  
                  <a className="btn-filled" href={`tel:${telPhoneNumber}`}> Call Now:  {heroSection.PhoneNo}</a>
                </div>
                </div>
                
              </div>
            </div>
            <div  className=" col-2 desktop" >
              <div className="col-2-image">
                <img src={refinance.image} alt="hero side image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
