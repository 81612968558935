import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(888) 627-0046",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div>
        <div>
          
          <section className="bg">
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <h2>DISCLAIMER</h2>
                    </div>
                  </div>
                 
                  <div>
                    <div>
                      <p>
                        The information provided by Choice Select Mortgage
                        (“we,” “us,” or “our”) on choiceselectmortgage.com (the
                        “Site”) is for general informational purposes only. All
                        information on the Site is provided in good faith,
                        however we make no representation or warranty of any
                        kind, express or implied, regarding the accuracy,
                        adequacy, validity, reliability, availability, or
                        completeness of any information on the Site. UNDER NO
                        CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
                        LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
                        USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED
                        ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON
                        ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec">
            <div>
              <div>
                <div>
                  <section>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <h2>PROFESSIONAL DISCLAIMER</h2>
                            </div>
                          </div>
                          
                          <div>
                            <div>
                              <p>
                                The information provided by Choice Select
                                Mortgage (“we,” “us,” or “our”) on
                                choiceselectmortgage.com (the “Site”) is for
                                general informational purposes only. All
                                information on the Site is provided in good
                                faith, however we make no representation or
                                warranty of any kind, express or implied,
                                regarding the accuracy, adequacy, validity,
                                reliability, availability, or completeness of
                                any information on the Site. UNDER NO
                                CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU
                                FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                                RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY
                                INFORMATION PROVIDED ON THE SITE. YOUR USE OF
                                THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON
                                THE SITE IS SOLELY AT YOUR OWN RISK.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <h2>TCPA DISCLAIMER</h2>
                            </div>
                          </div>
                          
                          <div>
                            <div>
                              <p>
                                Notwithstanding any current or prior election to
                                opt in or opt out of receiving telemarketing
                                calls, or SMS messages (text messages) from our
                                representatives, agents, affiliates, you
                                expressly consent to be contacted by CHOICE
                                SELECT MORTGAGE, our agents, representatives,
                                affiliates, or anyone calling on our behalf.
                                This is for any purposes relating to your
                                request for new mortgage / refinance mortgage
                                service, at any telephone number, or physical or
                                electronic address you provide or at which you
                                may be reached. You agree we may contact you in
                                any way, including SMS messages (text messages),
                                calls using prerecorded messages or artificial
                                voice, and calls and messages delivered using
                                auto telephone dialing system or an automatic
                                texting system.
                              </p>
                              <p>
                                Automated messages may be played when the
                                telephone is answered whether by you or someone
                                else. If an agent or representative of CHOICE
                                SELECT MORTGAGE calls, he or she may also leave
                                a message on your answering machine, voice mail,
                                or send one via text. You certify that the
                                telephone numbers that you have provided, are in
                                fact your contact numbers, and you agree to
                                receive calls at each phone number you have
                                provided CHOICE SELECT MORTGAGE. You agree to
                                promptly alert CHOICE SELECT MORTGAGE in the
                                event that you stop using a particular phone
                                number. Your cell/mobile telephone provider will
                                charge you according to the type of account you
                                carry with those companies. You may opt out of
                                any future contact via text message by replying
                                anytime with “STOP”. You agree that CHOICE
                                SELECT MORTGAGE may contact you by email, using
                                any email address you have provided or that you
                                provide in the future. You may opt out of any
                                future contact via email message by replying
                                anytime with “UNSUBSCRIBE”. CHOICE SELECT
                                MORTGAGE may listen to and/or record calls
                                between you and any representative without
                                notice, as permitted by applicable laws. For
                                example, we listen to calls to monitor for
                                quality purposes.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
