import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import hero_img2 from "../../Component/assets/images/two.webp";
export default function Contact({PhoneNumber, Contact}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="rel">
      <div className="Contact">
        <div className="container">
          <div className="row">
            <div className="card animate-from-left col-1" ref={cardRefs[0]} >
              <div className="col-1-content">
                <div>
                  {/* <span>
                    <img src={titlevector} alt="title_vector" />
                    Lorem ipsum dolor sit amet consectetur.
                  </span> */}
                </div>
                <div>
                  <h2>
                    {Contact.heading}
                  </h2>
                </div>
                <div></div>
                <div className="btn-group">
               
                   <a className="btn-filled" href={`tel:${telPhoneNumber}`}> Call Now:        {PhoneNumber.PhoneNo}</a> 
            
                </div>
              </div>
            </div>
            <div className="card animate-from-right col-2" ref={cardRefs[1]} >
              <div className="col-2-image">
                <img src={hero_img2} alt="hero side image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
