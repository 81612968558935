import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
import Contact from "../components/Contact";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import img1 from '../assets/images/icons/refinancing option.png';
import img2 from "../assets/images/icons/cashout refinancing.png";
import img3 from "../assets/images/icons/rate and term refinancing.png";
import img4 from "../assets/images/icons/fha streamline refinancing.png"
import img5 from '../assets/images/icons/expert-connection.png';
import img6 from "../assets/images/icons/personalized-matches.png";
import img7 from "../assets/images/icons/Streamlined Process.png";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_dl_lps";
import Refinnace from '../assets/images/three.webp'
export default function Dl_lp1() {
  useLoadScript("../Scripts/Home_dl_lps.js");
  const heroSection = 
    {
      SubHeading: "Get Matched with Top Mortgage Experts for Refinancing",
      Heading: "Seal the Deal on Your Refinancing Needs",
      Text: "",
      PhoneNo: "(855) 578-0607",

    }
    const ourServices =[
      {
        icon: img1,
        Heading: "Refinancing Options",
        Text: " Discover opportunities to lower your interest rate or monthly payments."

      },
      {
        icon: img2,
        Heading: "Cash-Out Refinancing",
        Text: "  Access your home equity for large expenses or investments"

      },
      {
        icon: img3,
        Heading: "Rate-and-Term Refinancing",
        Text: " Change your loan terms to better suit your financial goals."

      },
      {
        icon: img4,
        Heading: "FHA Streamline Refinancing",
        Text: "Take advantage of simplified refinancing options for FHA loans."

      }
    ]
 
  const whyChooseUs = [
    {
      icon: img5,
      Heading: "Expert Connections",
      Text: "We work with a network of highly rated mortgage professionals who specialize in refinancing, ready to assist you in finding the best refinance options."

    },
    {
      icon: img6,
      Heading: "Personalized Matches",
      Text: " Our team listens to your needs and preferences, ensuring that you are paired with the best possible mortgage provider for your refinance situation."

    },
    {
      icon: img7,
      Heading: "Streamlined Process",
      Text: " We make the journey to refinancing smooth and straightforward, eliminating the hassle of endless searching and uncertainty."

    }
  ]
  

  const PhoneNumber = {
     PhoneNo: "(855) 578-0607"

  }
  const ContactText ={
    heading: "Simplify Your Finances Refinance Your Mortgage Now"
  }
  const refinance ={
    image: Refinnace
  }
  return (
    <div>
            <ExternalScriptsLoader/>
      <Navbar PhoneNumber={PhoneNumber}/>
      <MobileNavbar PhoneNumber={PhoneNumber}/>
      <div  className="lp111">
        <div class="ripple-background">
          <div class="circle xxlarge shade1"></div>
          <div class="circle xlarge shade2"></div>
          <div class="circle large shade3"></div>
          <div class="circle medium shade4"></div>
          <div class="circle small shade5"></div>
        </div>
        <HeroSection refinance={refinance} heroSection={heroSection} />
      </div>
      <Services ourServices={ourServices} PhoneNumber={PhoneNumber}/>
      <WhyChooseUs whyChooseUs={whyChooseUs} PhoneNumber={PhoneNumber}/>
    
      <Contact Contact={ContactText} PhoneNumber={PhoneNumber}/>
      <Footer PhoneNumber={PhoneNumber}/>
    </div>
  );
}
