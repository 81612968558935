import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";

export default function Services({ ourServices , PhoneNumber}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="rel">
      <div className="Services">
        <div className="container">
          <div className="row">
            <div className="animate-from-right col-1" ref={cardRefs[0]}>
              <div className="col-1-content">
                <div>
                  {/* <span>
                    <img src={titlevector} alt="title_vector" />
                    Lorem ipsum dolor sit amet consectetur.
                  </span> */}
                </div>
                <div>
                  <h2>Our Services</h2>
                </div>
                <div>
                  {/* <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea
                    ullam laboriosam laudantium. Lorem ipsum dolor sit.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="animate-from-right col-1" ref={cardRefs[1]}>
              <div className="col-1-content">
                <div className="card_container">
                  {ourServices.map((item , index) => (
                    <div className="card " key={index}>
                      <div className="card-icon">
                        <img src={item.icon} alt="" />
                      </div>
                      <div className="card_content">
                        <h4>{item.Heading}</h4>
                        <p>{item.Text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="animate-from-left btn-group" ref={cardRefs[2]}>
              
              <a className="btn-filled"  href={`tel:${telPhoneNumber}`}> Call Now: {PhoneNumber.PhoneNo}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
