import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import img1 from './assets/images/icons/refinancing option.png';
import img2 from "./assets/images/icons/cashout refinancing.png";
import img3 from "./assets/images/icons/rate and term refinancing.png";
import img4 from "./assets/images/icons/fha streamline refinancing.png"
import img5 from './assets/images/icons/expert-connection.png';
import img6 from "./assets/images/icons/personalized-matches.png";
import img7 from "./assets/images/icons/Streamlined Process.png";
import img8 from "./assets/images/icons/apply by phone.png";
import img9 from "./assets/images/icons/get a quotation.png";
import img10 from "./assets/images/icons/close your loan.png";
import Refinnace from '../Component/assets/images/three.webp'
export default function landingPage() {
  const heroSection = 
    {
      SubHeading: "Experience Hassle-Free Mortgage Solutions",
      Heading: " Your Path to  Perfect Refinancing",
      Text: " Our network of trusted lenders and brokers offers a variety of refinancing options tailored to your unique financial needs. We simplify the process, guiding you to the ideal refinancing solution with ease and efficiency.",
      PhoneNo: "(888) 627-0046",

    }
    const ourServices =[
      {
        icon: img1,
        Heading: "Refinancing Options",
        Text: " Discover opportunities to lower your interest rate or monthly payments."

      },
      {
        icon: img2,
        Heading: "Cash-Out Refinancing",
        Text: "  Access your home equity for large expenses or investments"

      },
      {
        icon: img3,
        Heading: "Rate-and-Term Refinancing",
        Text: " Change your loan terms to better suit your financial goals."

      },
      {
        icon: img4,
        Heading: "FHA Streamline Refinancing",
        Text: "Take advantage of simplified refinancing options for FHA loans."

      }
    ]
 
  const whyChooseUs = [
    {
      icon: img5,
      Heading: "Expert Connections",
      Text: "We work with a network of highly rated mortgage professionals who specialize in refinancing, ready to assist you in finding the best refinance options."

    },
    {
      icon: img6,
      Heading: "Personalized Matches",
      Text: " Our team listens to your needs and preferences, ensuring that you are paired with the best possible mortgage provider for your refinance situation."

    },
    {
      icon: img7,
      Heading: "Streamlined Process",
      Text: " We make the journey to refinancing smooth and straightforward, eliminating the hassle of endless searching and uncertainty."

    }
  ]
  
  const HowitWorks =[
    {
      icon: img8,
      Heading: "APPLY BY PHONE",
      Text: (
        <>
          Get started by calling us at{" "}
          <a href="tel:+18886270046">(888) 627-0046</a>. One of our friendly mortgage experts will answer all your questions.
        </>
      )

    },
    {
      icon: img9,
      Heading: "GET A QUOTE",
      Text: "Find out how much you’re approved for, then go ahead with your refinance. We will work with you to get the best possible rate and terms for your refinance.",

    },
    {
      icon: img10,
      Heading: "CLOSE YOUR LOAN",
      Text: "Once your loan is approved, we will work with you to close your loan and get you the cash you need.",

    },
  ]
  const HowitWorksPhoneNo = {
    PhoneNo: "(888) 627-0046"
  }

  const PhoneNumber = {
     PhoneNo: "(888) 627-0046"

  }
  const ContactText ={
    heading: "Refinance Your Mortgage Save Big Today"
  }
  const refinance ={
    image: Refinnace
  }
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber}/>
      <MobileNavbar PhoneNumber={PhoneNumber}/>
      <HeroSection refinance={refinance} heroSection={heroSection}/>
      <Services ourServices={ourServices} PhoneNumber={PhoneNumber}/>
      <WhyChooseUs whyChooseUs={whyChooseUs} PhoneNumber={PhoneNumber}/>
      <HowItWorks PhoneNumber={PhoneNumber} HowitWorks={HowitWorks}/>
      <Contact Contact={ContactText} PhoneNumber={PhoneNumber}/>
      <Footer PhoneNumber={PhoneNumber}/>
    </div>
  );
}
