import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import hero_img2 from "../../Component/assets/images/one.webp";
import white from "../assets/images/icons/dot-white.png"


export default function WhyChooseUs({whyChooseUs, PhoneNumber}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="rel">
      <div className="WhyChooseUs mee">
        <div className="container">
          <div className="row">
          <div className="animate-from-left col-2 c2" ref={cardRefs[0]} >
              <div className="col-2-image desktop">
                <img src={hero_img2} alt="hero side image" />
              </div>
            </div>
            <div  className="col-1" >
              <div className="col-1-content">
                <div className="animate-from-right " ref={cardRefs[1]}>
                  <span>
                    <img src={white} alt="title_vector" />
                    Why Choose Us?
                  </span>
                </div>
                <div className="animate-from-right " ref={cardRefs[2]}>
                  <h2>
                    Refinance Your Mortgage <br />
                    Save Big Today
                  </h2>
                </div>
                <div>
                  {/* <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea
                    ullam laboriosam laudantium. Lorem ipsum dolor sit.
                  </p> */}
                </div>
                <div className="col-2-image mobile">
                <img src={hero_img2} alt="hero side image" />
              </div>
                <div className="card_container">
                  {whyChooseUs.map((item,index)=>
                   <div className=" card" key={index} >
                   <div className="card-icon">
                     <img src={item.icon} alt="" />
                   </div>
                   <div className="card_content">
                     <h4>{item.Heading}</h4>
                     <p>
                       {item.Text}
                     </p>
                   </div>
                 </div>
                  )}
                 
                 
                </div>

                <div className=" btn-group"  >
               
                  <a className="btn-filled"  href={`tel:${telPhoneNumber}`}> Call Now: {PhoneNumber.PhoneNo}</a>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  );
}
