import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import img1 from "../assets/images/icons/refinancing option.png";
import img2 from "../assets/images/icons/cashout refinancing.png";
import img3 from "../assets/images/icons/rate and term refinancing.png";
import img4 from "../assets/images/icons/fha streamline refinancing.png";
import img5 from "../assets/images/icons/expert-connection.png";
import img6 from "../assets/images/icons/personalized-matches.png";
import img7 from "../assets/images/icons/Streamlined Process.png";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_gg_lps";
import LeavesAnimation from "../components/LeavesAnimation";
import Refinnace from "../assets/images/lp2.webp";
export default function Gg_lp2() {
  useLoadScript("../Scripts/Home_gg_lps.js");
  const heroSection = {
    SubHeading: "Experience Hassle-Free Refinancing Solutions",
    Heading: "Refinance Your Mortgage with Ease",
    Text: "",
    PhoneNo: "(866) 592-2836",
  };
  const ourServices = [
    {
      icon: img1,
      Heading: "Refinancing Options",
      Text: " Discover opportunities to lower your interest rate or monthly payments.",
    },
    {
      icon: img2,
      Heading: "Cash-Out Refinancing",
      Text: "  Access your home equity for large expenses or investments",
    },
    {
      icon: img3,
      Heading: "Rate-and-Term Refinancing",
      Text: " Change your loan terms to better suit your financial goals.",
    },
    {
      icon: img4,
      Heading: "FHA Streamline Refinancing",
      Text: "Take advantage of simplified refinancing options for FHA loans.",
    },
  ];

  const whyChooseUs = [
    {
      icon: img5,
      Heading: "Expert Connections",
      Text: "We work with a network of highly rated mortgage professionals who specialize in refinancing, ready to assist you in finding the best refinance options.",
    },
    {
      icon: img6,
      Heading: "Personalized Matches",
      Text: " Our team listens to your needs and preferences, ensuring that you are paired with the best possible mortgage provider for your refinance situation.",
    },
    {
      icon: img7,
      Heading: "Streamlined Process",
      Text: " We make the journey to refinancing smooth and straightforward, eliminating the hassle of endless searching and uncertainty.",
    },
  ];

  const PhoneNumber = {
    PhoneNo: "(866) 592-2836",
  };
  const ContactText = {
    heading: "Find Your Perfect Refinance Fit Today",
  };
  const refinance = {
    image: Refinnace,
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div className="lp2222">
        <HeroSection refinance={refinance} heroSection={heroSection} />
      </div>
      <Services ourServices={ourServices} PhoneNumber={PhoneNumber} />
      <WhyChooseUs whyChooseUs={whyChooseUs} PhoneNumber={PhoneNumber} />

      <Contact Contact={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
